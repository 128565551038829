.sidebar
{

	.widget {
		@include make-sidebar-wrap();
		margin-bottom: 15px;
	}	

	p
	{
		font-size: 26px;
		line-height: 60px;
		margin-bottom: 0;
	}
    
    // fix contact form in sidebar
    .form-group-last {
        margin-bottom: 0;
    }
}

// move out of .sidebar so it applies to footers as well
.widget_archive,
.widget_recent_entries
{
	ul
	{
		@extend .list-unstyled;
		margin: 25px 15px 15px;

		li {
			margin-bottom: 15px;
		}

		li:last-child {
			margin-bottom: 0;
		}
	}
}
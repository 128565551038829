
.section-footer
{
	padding-top: $standardspacing;
	padding-bottom: $standardspacing;

	h4, li, a
	{
		color: $highlight-link-color;
	}

	a
	{
		&:hover,
		&:focus
		{
			color: $highlight-link-color-hover;
			text-decoration: underline;
		}
	}

	h4 {
		font-family: $copyfont;
		font-size: 20px;
		font-weight: bold;
		padding-bottom: $standardspacing / 4;		
	}

	.widget_recent_entries ul
	{
		@extend .list-unstyled;
		margin-left: 0;
		margin-top: 0;
	}

	.footer-contacts li
	{
		margin-bottom: 1em;
	}
}



.section-footer-copyright
{
	padding-top: $standardspacing;
	padding-bottom: $standardspacing;
	font-size: 0.8em;

	p, a
	{
		font-family: $copyfont;
		line-height: 1.5em;
		color: #333;
	}

	li, a, p
	{
		font-size: 1em;
	}	

	.row-footer-text {
		margin-top: $standardspacinghalf;
	}	

	.menu-copyright {
		margin-top: 1em;

		@media(min-width: $screen-md-min) {
			margin-top: 0;
			float: right;
		}
	}		
}

.row-footer-pest-control-services {
}

.section-footer-payment-methods {
	background: darken(#525252, 10%);

	img {
		margin-bottom: $standardspacing / 4;
	}
}
body {
	background: $brand-grey;
}

.site-boxed {
	background: $brand-white;
}

main.main {
    margin-bottom: 3em;
}

.main h2,
.main h3
{
	text-align: left;
	margin-bottom: 0.5em;
	margin-top: 1.5em;
}

.main h2
{
	font-size: 30px;
}

.main h2:first-child
{
	margin-top: 0;
}

.slidercontainer
{
	margin-bottom: $standardspacing;
}


.social-icon
{
	color: #DCCEB5;
	display: inline-block;
	width: 25px;
	height: 43px;
	line-height: 43px;
	font-size: 24px;
}

.social-icon:hover,
.social-icon:active
{
	color: #525252;
}

main .shortcode_menu {
	padding-left: 40px;
}

.pestrating {
	margin-bottom: 15px; 
	background: #fafafa; 
	padding: 10px;
	text-align: right;
	font-size: 20px;

	img {
		margin-left: 20px;		
	}
}

.homegrid {
	a:hover,
	a:active,
	a:focus {
		text-decoration: none;
		transition: all 0.2s ease-in;

		.img-homegrid {
			border-color: lighten($brand-green, 5%);
			transition: all 0.2s ease-in;
		}
	
		span {
			background-color: lighten($brand-green, 5%);
			transition: all 0.2s ease-in;
		}
	}

	.img-homegrid {
		margin-top: 10px;
		border: solid $brand-green;
		border-width: 5px 5px 0;
	}

	span {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: $brand-green;
		height: 4em;
		color: #fff;
		padding: 5px;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 10px;
		max-width: 160px;
		text-align: center;
	}
}
// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.comment-form input[type=email], 
.comment-form input[type=text], 
.comment-form input[type=url], 
.comment-form textarea, 
.form-control, 
.search-form .search-field {
  border-radius: 0;
}

.comment-form input[type=email]:focus, 
.comment-form input[type=text]:focus, 
.comment-form input[type=url]:focus, 
.comment-form textarea:focus, 
.form-control:focus, 
.search-form 
.search-field:focus {
  border-color: $form-focus-color;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($form-focus-color, 0.6)
}

// contact form 7
div.wpcf7 img.ajax-loader {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
}

div.wpcf7 .wpcf7-not-valid-tip {
  border-color: #b22222;
    background: #E6E6E6;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;    
}

div.wpcf7 .ajax-loader {
  background-color: #fff;
}

div.wpcf7-response-output {
    margin: 0;
    padding: .5em;    
    display: block;
    text-align: center;
}

div.wpcf7-mail-sent-ok {
    border: none;
    background-color: lighten($brand-green, 5%);
    color: $brand-white;
}

div.wpcf7-validation-errors {
    background-color: #b22222;
    border-color: #fff;
    color: #fff;
}

.btn-group-lg>.btn, 
.btn-lg, 
.comment-form .btn-group-lg>input[type=submit], 
.search-form .btn-group-lg>.search-submit {
  border-radius: 0;
}
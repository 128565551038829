.bg-secondary-lowcontrast .testimonials-fullwidth {
	.testimonial p,
	.attrib,
	.testimonial-name,
	.testimonial-company
	{
		color: #fff;
	}
}

.testimonials-fullwidth
{
	.testimonial
	{
		background: url(../images/quote-mark.png) no-repeat;
		padding-left: 85px;
	}

	@media (min-width: $screen-sm-min) {
		.testimonial .inner
		{
			background: url(../images/quote-mark-close.png) right top no-repeat;
			padding-right: 85px;
			border: none;
		}
	}

	.testimonial-carousel-controls a
	{
		color: #231f20;	
	}

	.carousel-inner {
		margin-bottom: 50px;
	}

	.carousel-indicators {
		bottom: -50px;

		li {
			border-top: none;
			border-left: none;
			border-right: none;
			border-radius: 0;
			border-bottom-width: 2px;
			width: 15px;
			height: 15px;
			transition: border-width 0.2s ease-in-out;
		}

		.active {
			border-bottom-width: 8px;
			background-color: transparent;
		}		
	}
}
.custom-logo
{
	display: block;
	max-width: 100%;
    height: auto;

	@media (max-width: $screen-sm-max) {
		@include center-block();
		margin-bottom: 10px;
	}
}

.container-headlines {
	background: $brand-green url(../images/headline-bg-02.png);
	color: $brand-white;
	margin-bottom: 15px;
}
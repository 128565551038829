// Grid system
.site-boxed {
  margin-right: auto;
  margin-left: auto;
  @include clearfix;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// colours
$brand-blue:                        #0099FF;
$brand-green:                       #227447;
$brand-darkblue:                    #0d5480;
$brand-black:                       #212121;
$brand-primary:                     $brand-blue;
$brand-white:                       #fff;
$brand-grey:                        #f4f4f4;

$primary-link-color:				$brand-green;
$primary-link-border-color:			darken($primary-link-color, 15%);

//$primary-link-color-hover:		lighten($highlight-bg-color, 15%);
//$primary-link-border-color-hover:	darken($highlight-bg-color, 15%);

$primary-bg-color: 					#eee;


$highlight-bg-color: 				$brand-black;
$highlight-link-color:			    $brand-white;
$highlight-link-color-hover:		darken($brand-grey, 25%);

$secondary-bg-lowcontrast:			$brand-green;

// font sizes
$font-size-before-mobile: 10px;
$font-size-before-normal: 14px;
$font-size-text-mobile: 14px;
$font-size-text-normal: 18px;

// margins
// used by util classes
$standardspacing:                   50px;
$standardspacinghalf:               ($standardspacing / 2);

// typography
// Dont forget: If you specify a Google Font below, you need to register it in .lib/setup.php/GOOGLE_FONTS
$headerfont: 						"Saira Extra Condensed", "Open Sans", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
$copyfont: 							Montserrat, "Open Sans", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
$link-color:                        $brand-green;
$link-hover-color:                  darken($brand-green, 10%);

// the outline / stroke colour for form elements with focus
$form-focus-color:                  $brand-green;

// bootstrap overrides

// uncomment for custom navbar break
// note: can't use $screen-XX-min variables as BS not included yet
// $grid-float-breakpoint: 992px; 

$navbar-default-link-hover-color:	#000;
$navbar-default-link-active-color:	#000;
/*
$navbar-height: 80px;
$navbar-padding-vertical: 17px;
$navbar-padding-horizontal: 15px;

$navbar-default-bg: $brand-darkergreen;
$navbar-default-border: transparent;
$navbar-default-link-hover-color:	#000;
$navbar-default-link-active-color:	#000;

$navbar-default-toggle-icon-bar-bg: #fff;
$navbar-default-toggle-hover-bg: darken($navbar-default-bg, 12.5%);
*/


// testimonials-speechbubble overrides
$speechbubble-quote-backgroundcolor:	$highlight-bg-color;
$speechbubble-quote-textcolor:			$highlight-link-color;
$speechbubble-attrib-textcolor:			$highlight-bg-color;

// header contact styles
$contact-entries-label:                 $brand-blue;
$contact-entries-link:                  $brand-green;
.section
{
	padding-top: $standardspacing;
	padding-bottom: $standardspacing;
}

.section-coveragearea {
    color: $brand-white;

    img {
        border: 2px solid $brand-black;
    }

    ul.list-icon li {
        margin: 0.8em 0.8em 0.8em 1.5em;
    }

    @media (min-width: $screen-sm-min) {
        h3 {
            text-align: left;
        }
    }
}

.section-logo
{
	padding-top: 20px;
	padding-bottom: 20px;	
}

.section-innerheader
{
	padding-top: 20px;
	padding-bottom: 20px;	
}

.section-welcome
{
	text-align: center;

    p
    {
        font-size: 1.3em;
        
        &:last-child
        {
            margin-bottom: 0;
        }	
    }
}

.section-oversize {
    h3
    {
        font-size: 2.2em;	
    }

    p
    {
        font-size: 1.2em;
    }
}

.section-testimonials {       
    h2 {
        margin-top: 60px;
    }
    
    .testimonial-carousel-controls {
        margin-bottom: 60px;
        
        a {
            color: $brand-blue;
        }
        
        a:hover,
        a:active {
            color: darken($brand-blue, 12.5%);
        }
    }
}

.section-columns {
    img,
    h3
    {
        text-align: center;
        margin-left: auto;
        margin-right: auto;	
    }

    .btn
    {
        text-transform: uppercase;
    }
}

.columns-content
{
	margin-bottom: 1em;
}



.bg-white
{
	background-color: #fff;
} 

.bg-secondary-lowcontrast
{
    background-color: $secondary-bg-lowcontrast;
    
    h3 {
        color: $brand-white;
    }
} 

.bg-lightgrey
{
	background-color: #f9f9f7;	
}

.bg-lightbrown
{
	background-color: #ebe0cc;	
}

.bg-darkgrey
{
	background-color: #525252;
}

.bg-mediumgrey {
    background-color: #898989;
}

.bg-footer-brownbar
{
	border-bottom: 8px solid #b6ad9e;
}

.bg-blue h2,
.bg-blue p,
.bg-blue .testimonial-carousel-controls a
{
	color: #fff;
}


.container.inverted {
    background-color: $brand-green;
    color: $brand-white;
    padding-top: $standardspacing;
    padding-bottom: $standardspacing;
}
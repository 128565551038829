.attrib
{
	border-left: 3px solid darken($brand-green, 12.5%);
	padding: 4px 0 4px 8px;
}


.strong-view.default .strong-content {
	margin: 0;
}

.strong-view.default .testimonial-inner {
	border: 0;
}

.strong-view.default .testimonial {
	border: 3px solid $brand-green;
}

.strong-view.default .testimonial-client {
    margin: 20px -20px -20px;
	padding: 20px;
	background: $brand-grey;
}
.padded {
    padding: 15px;
}

.margintop
{
	margin-top: $standardspacing;
}

.margintail
{
	margin-bottom: $standardspacing;
}

.margintoptail
{
	margin-top: $standardspacing;
  margin-bottom: $standardspacing;
}

.margintophalf {
    margin-top: $standardspacinghalf;
}

.margintailhalf{
    margin-bottom: $standardspacinghalf;
}

.margintoptailhalf
{
	margin-top: $standardspacinghalf;
  margin-bottom: $standardspacinghalf;
}

.nomargintail {
    margin-bottom: 0;
}

.nopaddingtail {
    padding-bottom: 0 !important;
}

.image-inset
{
  border: 10px solid $brand-green;
  margin-bottom: 25px;
}

// Full Width Containers in Limited Width Parents
// https://css-tricks.com/full-width-containers-limited-width-parents/
.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.list-chevron {
  list-style-type: none;
  
  li a {
    padding-left: 1.3em;
    
    &:before {
      content: "\f101"; /* FontAwesome Unicode */
      font: 14px FontAwesome;
      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.3em; /* same as padding-left set on li */
    }
  }
}

.list-twocol {
	li
	{
		@include make-sm-column(6);
        padding-left: 0;
        padding-right: 0;
	}
}

.list-columns {
	li
	{
    @include make-sm-column(3);
    padding-left: 0;
    padding-right: 0;    
	}
}

.list-pipeseparator
{
	li
	{
	  padding-right: 0;

	  &:before {
			content: "| ";
			padding-right: 5px;
		}

		&:first-child:before {
			content: "";
			padding-right: 0;
		}
	}
}

// center align a list
.list-centeralign {
    width: 100%;
    text-align: center;
}

.list-centeralign > li {
    float: none; // ignore this warning it resets some scenarios
    display: inline-block;
}

// icon list
// https://codepen.io/mburnette/pen/rdGJc
// using <ul class="list-icon icon-map-marker">
ul.list-icon,
ul.list-icon li {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.list-icon {
  margin: 0;
}

ul.list-icon li {
  margin: 1em;
  margin-left: 1.5em;
}

ul.list-icon li:before {
  float: left;
  margin-left: -1.5em;
}

// add custom icons for icon-list here:
ul.icon-map-marker li:before {
  content: '\f041';
  font-family: 'FontAwesome';
  color: $brand-white;
}

ul.icon-caret-right li:before {
  content: '\f0da';
  font-family: 'FontAwesome';
  color: $brand-green;
}

// https://codepen.io/mburnette/pen/rdGJc
ul.icon-check-square-o,
ul.icon-check-square-o li {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.icon-check-square-o {
  margin: 0;
}

ul.icon-check-square-o li {
  margin: 1em;
  margin-left: 3em;
}

ul.icon-check-square-o li:before {
  content: '\f046';
  font-family: 'FontAwesome';
  float: left;
  margin-left: -1.5em;
  color: $brand-green;
}

// keep headings aligned
main.main h3:first-child {
    margin-top: 0;
}

// fix the facebook widget width
.likebox-wrapper * {
   width: 100% !important;
}

@mixin make-wrap() {
		border: solid $brand-green;
		border-width: 0 5px 5px;
		padding: 0 10px 10px;

    .wrap-title, .h1 {
      padding: 10px 100px 10px 15px;
      background: $brand-green;
      color: $brand-white;
      font-size: 24px;
      text-align: left;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 15px;
    }
}


@mixin make-sidebar-wrap() {
		@include make-wrap();

		h3 {
			margin: 0 -10px 10px;
			background-color: $brand-green;
			color: $brand-white;
			padding: 10px;
		}
}

table.pest-table {
  border: 3px solid $brand-green;

  td, th {
    padding: 15px;
    vertical-align: top;
  }

  th {
    padding-bottom: 0;
  }

  td:first-child {
    width: 40%;
  }

  margin-bottom: $standardspacing;
}

// show magnify glass on image hover
// https://gist.github.com/srikat/9aee586a8ff153028379
.expandable-image {
	position: relative;
	display: inline-block;
}

.expandable-image img {
	vertical-align: top;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #151515; /* for dark overlay on top of the image */
	opacity: 0;
	transition: opacity 0.35s, transform 0.35s;
}

.expandable-image:hover .overlay {
	opacity: 0.8;
}

.magnifying-glass-icon {
	color: #fff;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	padding: 20px;
	border: 1px solid #fff;
	line-height: 1;
}

.magnifying-glass-icon:hover {
	background: rgba(0,0,0,0.5);
	color: #fff;
}

@media only screen and (max-width: 400px) {
	.expandable-image {
		display: block;
	}
}
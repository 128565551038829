.testimonials-sidebar
{
    .testimonial-name,
    .testimonial-company
    {
        border-left: 3px solid $brand-black;
        padding: 4px 0 4px 8px;
    }
    
	p
	{
		font-size: 16px;
		line-height: 22px;
		margin-bottom: .75em;
	}

	blockquote
	{
		border-left: none;
		padding: 0;
		margin-bottom: $standardspacing;
	}

	.carousel-control.left, 
	.carousel-control.right 
	{
		background-image:none !important;
	}

	.carousel-control
	{
		background:	#ddd;
		color:#999;
		padding: 4px 0;
		width:26px;
		top:auto;	
		left:auto;
		bottom:0;
		opacity:1;
		text-shadow:none;
	}

	.carousel-control.right
	{
		right:10px;
	}

	.carousel-control.left
	{
		right: 40px;
	}
}